import React, { useState, useMemo, useEffect, useContext } from 'react';
import { ThemeContext } from '../index';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel
} from '@mui/material';
import { Select, Spin, DatePicker } from 'antd';
import { useNavigate } from 'react-router';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import dayjs from 'dayjs';
import moment from 'moment';
import { Constants } from './common/constants';
import { toast } from 'react-toastify';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { ClearIcon } from '@mui/x-date-pickers';
import axios from 'axios';
const { REACT_APP_CORE_URL, FLASK_API_URL } = Constants;

const PeopleManagement = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [callLogsData, setCallLogsData] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState('5');
  const [totalRecords, setTotalRecords] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const [currentTab, setCurrentTab] = useState(false);
  const [file, setFile] = useState();
  const [mobileOptions, setMobileOptions] = useState([]);
  const [contactedOptions, setContactedOptions] = useState([]);
  const [isGraph, setIsGraph] = useState(true);
  const [mostContactedData, setMostContactedData] = useState({});
  const fetchWithAuth = useFetchWithAuth();
  const { RangePicker } = DatePicker;

  const handleClickOpen = (userId) => {
    setDeleteUserId(userId);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteUserId(undefined);
    setOpen(false);
  };

  const dateHandler = (date) => {
    if (date) {
      const fromDate = moment
        .tz(date[0].toDate(), 'Asia/Kolkata')
        .add(5.5, 'hours')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const toDate = moment
        .tz(date[1].toDate(), 'Asia/Kolkata')
        .add(1, 'day')
        .add(5.5, 'hours')
        .add(-1, 'minutes')
        .add(59, 'seconds')
        .add(999, 'milliseconds')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

      setFilters({
        ...filters,
        startDate: fromDate,
        endDate: toDate
      });
    } else {
      setFilters({
        ...filters,
        startDate: undefined,
        endDate: undefined
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'calling',
        header: 'Mobile No.',
        size: 100
      },
      {
        accessorKey: 'call_type',
        header: 'Call Type',
        size: 150
      },
      {
        accessorKey: 'duration',
        header: 'Duration',
        size: 150
      },
      {
        accessorKey: 'called',
        header: 'Contacted Mobile',
        size: 150
      }
    ],
    []
  );

  const columnsMostContacted = useMemo(
    () => [
      {
        accessorKey: 'first_call_time', //normal accessorKey
        header: 'First Call Time',
        size: 150
      },
      {
        accessorKey: 'last_call_time',
        header: 'Last Call Time',
        size: 100
      },
      {
        accessorKey: 'daily_frequency',
        header: 'Frequency',
        size: 150
      },
      {
        accessorKey: 'call_count', //normal accessorKey
        header: 'Call Count',
        size: 150
      },
      {
        accessorKey: 'average_duration', //normal accessorKey
        header: 'Average Duration',
        size: 150
      },
      {
        accessorKey: 'part_of_day', //normal accessorKey
        header: 'Part of Day',
        size: 150
      }
    ],
    []
  );

  useEffect(() => {
    if (!currentTab) getCallLogsData();
    else getMostContactedData();
  }, [currentPage, rowsPerPage, currentTab]); // eslint-disable-line

  useEffect(() => {
    getContactedOptions();
    getMobileOptions();
  }, []); // eslint-disable-line

  const getCallLogsData = () => {
    setLoading(true);
    const url = new URL(`${REACT_APP_CORE_URL}/getAllCDRData`);
    if (filters.calling) url.searchParams.append('calling', filters.calling);
    if (filters.called) url.searchParams.append('called', filters.called);
    if (currentPage) url.searchParams.append('page', currentPage);
    if (rowsPerPage) url.searchParams.append('pageSize', rowsPerPage);
    if (filters.startDate)
      url.searchParams.append('startDate', filters.startDate);
    if (filters.endDate) url.searchParams.append('endDate', filters.endDate);
    fetchWithAuth(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setCallLogsData(data.data);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getMostContactedData = () => {
    setLoading(true);
    const jsonData = {
      phoneNumber: filters.calling || null,
      topN: Number(rowsPerPage) || null,
      dateRange:
        filters.startDate && filters.endDate
          ? {
              startDate: filters.startDate,
              endDate: filters.endDate
            }
          : null,
      contactNumber: filters.called || null
    };
    console.log('Sending JSON data:', JSON.stringify(jsonData));
    axios
      .post(`${FLASK_API_URL}/analyze`, jsonData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((data) => {
        console.log(data.data);
        if (!filters.called) {
          setMostContactedData({
            images: [
              `data:image/png;base64,${data.data.graph1}`,
              `data:image/png;base64,${data.data.graph2}`,
              `data:image/png;base64,${data.data.graph3}`,
              `data:image/png;base64,${data.data.graph4}`,
              `data:image/png;base64,${data.data.graph5}`,
              `data:image/png;base64,${data.data.graph6}`,
              `data:image/png;base64,${data.data.graph7}`,
              `data:image/png;base64,${data.data.graph8}`,
              `data:image/png;base64,${data.data.graph9}`
            ]
          });
          setIsGraph(true);
        } else {
          setMostContactedData(data.data);
          setIsGraph(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        if (filters.calling) toast.error('Failed to fetch Analysis Data');
        console.log(err);
        setLoading(false);
      });
  };

  const getMobileOptions = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getCallingUnique`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setMobileOptions(
          data.sanitizedResult.map((option) => ({
            label: option,
            value: option
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getContactedOptions = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getCalledUnique`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setContactedOptions(
          data.sanitizedResult.map((option) => ({
            label: option,
            value: option
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getData = () => {
    if (currentTab) getMostContactedData();
    else getCallLogsData();
  };

  const table = useMaterialReactTable({
    columns: currentTab
      ? columnsMostContacted
        ? columnsMostContacted
        : []
      : columns
        ? columns
        : [],
    initialState: {
      density: 'compact',
      columnVisibility: {
        aadhar: false,
        // user_id: false,
        city: false,
        country: false,
        wanted: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    data: currentTab ? mostContactedData : callLogsData,
    enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false,
    renderTopToolbarCustomActions: () => (
      <FilterToolbar setFilters={setFilters} />
    )
  });

  const FilterToolbar = ({ setFilters }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          marginLeft: '1rem'
        }}
      >
        <div>
          <label style={{ fontSize: '12px', marginLeft: '5px' }}>
            Mobile No.
          </label>{' '}
          <Select
            placeholder="Select Mobile No."
            options={mobileOptions}
            style={{
              background: theme.palette.mode === 'dark' ? '#374151' : '',
              color: theme.palette.mode === 'dark' && '#ffffff',
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid #4b5563'
                  : '1px solid #d9d9d9'
            }}
            allowClear
            onChange={(e) => {
              console.log(e);
              setFilters({ ...filters, calling: e });
            }}
            showSearch
            className="w-[20rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
            maxTagCount={'responsive'}
            value={filters.calling}
          />
        </div>
        <div>
          <label style={{ fontSize: '12px', marginLeft: '5px' }}>
            Contacted Number
          </label>{' '}
          <Select
            placeholder="Select Contacted Number"
            options={contactedOptions}
            onChange={(e) => {
              setFilters({ ...filters, called: e });
            }}
            allowClear
            showSearch
            className="rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
            style={{
              background: theme.palette.mode === 'dark' ? '#374151' : '',
              color: theme.palette.mode === 'dark' && '#ffffff',
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid #4b5563'
                  : '1px solid #d9d9d9',
              height: '32px'
            }}
            value={filters.called}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ fontSize: '12px', marginLeft: '5px' }}>Dates</label>{' '}
          <RangePicker
            className="dark:rounded-full rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={
              filters.startDate
                ? [
                    dayjs(
                      moment(new Date(filters?.startDate)).format('YYYY-MM-DD')
                    ),
                    dayjs(
                      moment(new Date(filters?.endDate)).format('YYYY-MM-DD')
                    )
                  ]
                : []
            }
            format={'DD/MM/YYYY'}
            onChange={(value) => dateHandler(value)}
          />
        </div>
        <Button
          type="primary"
          style={{ marginTop: '20px', backgroundColor: '#2CD074' }}
          onClick={getData}
        >
          Submit
        </Button>
      </div>
    );
  };

  const handleNextPage = () => {
    if (currentPage === Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage === 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFileImport = (e) => {
    setLoading(true);
    const files = e.target.files;
    if (files) {
      setFile(e.target.files[0]);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      fetchWithAuth(`${REACT_APP_CORE_URL}/createCDRData`, {
        method: 'POST',
        credentials: 'include',
        body: formData
      })
        .then(() => {
          setFile();
          getCallLogsData();
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <div className="flex mb-2 justify-between">
        <div>
          <button
            style={{
              backgroundColor: currentTab ? 'inherit' : '#2CD074',
              color: currentTab ? 'black' : 'white',
              border: '1px solid #2CD074',
              marginRight: '5px'
            }}
            className="custom-button"
            onClick={() => {
              getCallLogsData();
              setCurrentTab(false);
            }}
          >
            Call Logs
          </button>
          <button
            style={{
              backgroundColor: currentTab ? '#2CD074' : 'inherit',
              color: currentTab ? 'white' : 'black',
              border: '1px solid #2CD074',
              marginRight: '5px'
            }}
            className="custom-button"
            onClick={() => setCurrentTab(true)}
          >
            Analysis
          </button>
        </div>
        <div>
          <label
            htmlFor="fileInput"
            style={{
              cursor: 'pointer',
              backgroundColor: '#2CD074',
              border: '1px solid #2CD074'
            }}
            className="custom-button"
          >
            Import
          </label>
          <input
            id="fileInput"
            style={{ display: 'none' }}
            type="file"
            // accept=".csv,.xlsx,.xls,.zip"
            onChange={handleFileImport}
          />
          {file && (
            <div
              style={{ margin: '5px', display: 'flex', alignItems: 'center' }}
            >
              {file?.name}{' '}
              <ClearIcon
                onClick={() => setFile()}
                style={{
                  border: '1px solid black',
                  margin: '0px 10px 0px 10px',
                  borderRadius: '3px'
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div id="peopleManagementTable">
        {(!currentTab || !isGraph) && <MaterialReactTable table={table} />}
        {currentTab && isGraph && (
          <>
            <FilterToolbar setFilters={setFilters} />
            <div className="w-full items-center flex flex-col">
              {mostContactedData?.images &&
                mostContactedData.images?.map((image) => {
                  return <img src={image} key={image} />;
                })}
              {!mostContactedData?.images && (
                // <div className="text-center italic m-5">Choose a Mobile No.</div>
                <div className="journey-card text-[#898989] mt-5">
                  Choose a Mobile No.
                </div>
              )}
            </div>
          </>
        )}
        <div className="mt-3 mb-5 flex justify-between">
          <div className="flex items-center rows-per-page">
            <span>{currentTab ? 'Top N:' : 'Rows Per Page:'}</span>
            <Select
              className="mx-2 w-[3.8rem] h-[3rem]"
              options={[
                {
                  value: '5',
                  label: '5'
                },
                {
                  value: '10',
                  label: '10'
                },
                {
                  value: '20',
                  label: '20'
                }
              ]}
              onChange={(e) => {
                setRowsPerPage(e);
                setCurrentPage(1);
              }}
              value={rowsPerPage}
            />
            <DialogBox
              handleClose={handleClose}
              open={open}
              userId={deleteUserId}
              getPeopleData={getCallLogsData}
            />
          </div>
          {!currentTab && (
            <div className="flex items-center ">
              <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
              <div>
                {currentPage}/{Math.ceil(totalRecords / parseInt(rowsPerPage))}
              </div>
              <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default PeopleManagement;

const DialogBox = ({ open, handleClose, userId, getCallLogsData }) => {
  const [reason, setReason] = useState();
  const fetchWithAuth = useFetchWithAuth();
  const deleteUser = () => {
    if (!reason) return toast.error('Reason cannot be empty');
    fetchWithAuth(`${REACT_APP_CORE_URL}/deleteUserInfo/${userId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ reason: reason })
    })
      .then((res) => {
        if (!res.ok) throw new Error('Bad Request');
        toast.success('DELETED USER');
        handleClose();
        return getCallLogsData();
      })
      .catch((err) => {
        console.log(err);
        toast.error('SOMETHING WENT WRONG');
      });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        zIndex: 500
      }}
    >
      <DialogTitle id="alert-dialog-title">Delete User?</DialogTitle>
      <DialogContent>
        <InputLabel>Reason</InputLabel>
        <Input
          type="text"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outline" className="text-black">
          Cancel
        </Button>
        <Button onClick={deleteUser} autoFocus color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
