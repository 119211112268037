import React, { useState, useEffect } from 'react';
import { Chip, TextField } from '@mui/material';
import { Select, Spin, Avatar } from 'antd';
import { alert_level_options, status_options } from './anomaly-options';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { Link, useParams } from 'react-router-dom';
import { Constants } from './common/constants';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
const { REACT_APP_CORE_URL } = Constants;

export const AnomalyDetails = () => {
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState({});
  const [desc, setDesc] = useState({});
  const [anomalyDetails, setAnomalyDetails] = useState({});
  const [peopleData, setPeopleData] = useState([]);
  const fetchWithAuth = useFetchWithAuth();
  const { id } = useParams();
  useEffect(() => {
    getAnomalyDetailsData();
  }, []);
  const getAnomalyDetailsData = () => {
    setLoading(true);
    const url = new URL(`${REACT_APP_CORE_URL}/anomalies/${id}`);
    fetchWithAuth(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(async (res) => res.json())
      .then((data) => {
        const {
          anomalyType,
          createdAt,
          description,
          closeTime,
          alertType,
          status,
          assignedTo,
          resolutionRemarks,
          user,
          ...restOfDetails
        } = data;
        console.log(data, 'joy gutu');
        const t = arrayBufferToBase64(user?.imagebase64.data);
        const base64Image = `data:image/jpeg;base64,${t}`;
        setReadOnly({
          Image: base64Image,
          Name: user?.f_name,
          'Anomaly Type': anomalyType,
          'Created At': new Date(createdAt).toDateString(),
          'Close Time': closeTime
        });
        const { userId, userIds, ...d } = description;

        setDesc(d);

        if (userId) {
          userId.forEach((id) => {
            getPeopleData(id);
          });
        }
        if (userIds) {
          userIds.forEach((id) => {
            getPeopleData(id);
          });
        }
        setAnomalyDetails({
          alertType,
          status,
          assignedTo,
          remarks: resolutionRemarks.length > 0 ? resolutionRemarks[0] : ''
        });
        console.log(restOfDetails);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getPeopleData = (id) => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getUserInfoById/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        const { user_id, imagebase64 } = data.result;
        setPeopleData([
          ...peopleData,
          { name: data.result?.f_name, user_id, image: imagebase64 }
        ]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const updateAnomalyDetails = () => {
    setLoading(true);
    const url = new URL(`${REACT_APP_CORE_URL}/anomalies/${id}`);
    const { remarks, ...restOfDetails } = anomalyDetails;
    const updatedAnomaly = {
      ...restOfDetails,
      resolutionRemarks: [remarks]
    };
    fetchWithAuth(url.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedAnomaly)
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        toast.success('Updated Anomaly Successfully');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error('Failed to update Anomaly');
      });
  };
  return (
    <Spin size="large" spinning={loading}>
      <div className="flex gap-5 w-full capitalize">
        <div className="w-1/3">
          <div className="flex flex-col gap-5 rounded overflow-hidden shadow-lg p-10">
            {readOnly &&
              Object.entries(readOnly)
                .filter(([key, value], index) => value)
                .map(([key, value], index) => {
                  if (key == 'Image') {
                    return (
                      <img
                        className="w-[6rem] h-[6rem] object-cover rounded-full"
                        src={value}
                        key={index}
                      />
                    );
                  }
                  if (key == 'Anomaly Type') {
                    return (
                      <Chip
                        label={value}
                        variant="outlined"
                        key={index}
                        color="success"
                      />
                    );
                  }
                  return (
                    <div key={index}>
                      {key} : {value}
                    </div>
                  );
                })}
          </div>
          <div className="flex flex-col gap-5 rounded overflow-hidden shadow-lg p-10">
            <h1 className="font-bold">Description</h1>
            {desc && desc.shortDescription && (
              <p className="">{desc.shortDescription}</p>
            )}
            {console.log(desc)}
            {desc && (
              <div className="grid grid-cols-1 gap-4">
                {Object.entries(desc)
                  .filter(
                    ([key, value]) =>
                      value !== '' &&
                      value !== null &&
                      key !== 'userIds' &&
                      key !== 'shortDescription'
                  )
                  .map(([key, value], index) => {
                    let formattedValue = value;

                    // Check if the key is 'timestamps' and format the value if it's an ISO string
                    // if (key === 'timestamps' ) {
                    //   formattedValue = dayjs(value).format('DD/MM/YYYY HH:mm');

                    // }
                    if (['date_time', 'timestamps'].includes(key)) {
                      formattedValue = format(
                        new Date(value),
                        'do MMMM yyyy, h:mm aaa'
                      ); // Format as 1st Jan 2024 1:30 PM
                    }

                    return (
                      <div key={index} className="flex">
                        <span className="font-bold mr-2">
                          {key.replace(/[_-]/g, ' ')}:
                        </span>
                        <span>{formattedValue}</span>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-10 w-2/3">
          <div className="flex gap-10">
            <div>
              <label
                style={{
                  fontSize: '12px',
                  marginLeft: '5px',
                  position: 'relative',
                  top: '10px'
                }}
              >
                Alert Level
              </label>
              <Select
                label="Alert Type"
                value={anomalyDetails.alertType}
                style={{
                  width: '410px',
                  height: '60px'
                }}
                options={alert_level_options}
                fullWidth
                onChange={(e) => {
                  setAnomalyDetails({ ...anomalyDetails, alertType: e });
                }}
              />
            </div>
            <div>
              <label
                style={{
                  fontSize: '12px',
                  marginLeft: '5px',
                  position: 'relative',
                  top: '10px'
                }}
              >
                Status
              </label>
              <Select
                label="Status"
                value={anomalyDetails.status}
                style={{
                  width: '410px',
                  height: '60px'
                }}
                options={status_options}
                fullWidth
                onChange={(e) => {
                  setAnomalyDetails({ ...anomalyDetails, status: e });
                }}
              />
            </div>
          </div>
          <div className="flex gap-10">
            <TextField
              label="Assigned to"
              value={anomalyDetails.assignedTo}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'green' // Replace with your desired color
                  }
                }
              }}
              InputLabelProps={{
                focused: false,
                shrink: true
              }}
              fullWidth
              onChange={(e) => {
                setAnomalyDetails({
                  ...anomalyDetails,
                  assignedTo: e.target.value
                });
              }}
            />
          </div>
          <div className="flex">
            <TextField
              label="Remarks"
              value={anomalyDetails.remarks}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'green' // Replace with your desired color
                  }
                }
              }}
              InputLabelProps={{
                focused: false,
                shrink: true
              }}
              fullWidth
              multiline
              onChange={(e) => {
                setAnomalyDetails({
                  ...anomalyDetails,
                  remarks: e.target.value
                });
              }}
            />
          </div>
          <div className="flex justify-end">
            <button
              style={{
                backgroundColor: '#2CD074',
                color: 'white',
                border: '1px solid #2CD074',
                marginRight: '5px',
                fontSize: 20
              }}
              className="custom-button"
              onClick={updateAnomalyDetails}
            >
              Update
            </button>
          </div>
          <div className="flex gap-5 overflow-hidden rounded shadow-lg p-5">
            {peopleData.map((people) => {
              return (
                <>
                  <Link to={`/edit-resident-details/${people.user_id}`}>
                    <Chip
                      avatar={<Avatar alt="Natacha" src={people.image} />}
                      label={people.name}
                      variant="outlined"
                      sx={{
                        fontSize: '1rem', // Adjust text size if needed
                        height: '50px', // Adjust chip height
                        minWidth: '100px',
                        borderRadius: '15%', // Adjust chip width
                        '& .MuiChip-label': {
                          fontSize: '0.875rem' // Adjust font size of the label
                        },
                        '& .MuiChip-avatar': {
                          width: 40,
                          height: 40
                        }
                      }}
                    />
                  </Link>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </Spin>
  );
};
