import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Alert,
  Button
} from '@mui/material';
import { grey, green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { Constants } from './common/constants';
const { REACT_APP_CORE_URL } = Constants;
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { reportActions } from './common/constants.jsx';

const ReportsDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState(null);
  const fetchWithAuth = useFetchWithAuth();
  const { RangePicker } = DatePicker;

  const today = dayjs().format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const fetchReportData = async (start, end) => {
    try {
      const response = await fetchWithAuth(`${REACT_APP_CORE_URL}/reports`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ startDate: start, endDate: end })
      });

      const result = await response.json();

      if (result.success) {
        setReportData(result.data.length ? result.data : []);
        setError(
          result.data.length
            ? null
            : 'No reports available for this date range.'
        );
      } else {
        setError(result.message || 'Failed to fetch report data');
      }
    } catch (error) {
      console.error('Error fetching report data:', error);
      setError('Error fetching report data');
    }
  };

  useEffect(() => {
    fetchReportData(today, today);
  }, []);

  const handleDateChange = (dates) => {
    if (dates) {
      const start = dates[0].startOf('day').format('YYYY-MM-DD');
      const end = dates[1].endOf('day').format('YYYY-MM-DD');
      setFromDate(start);
      setEndDate(end);
    } else {
      setFromDate(today);
      setEndDate(today);
    }
  };

  const handleSubmit = () => {
    fetchReportData(fromDate, endDate);
  };

  const handleViewAll = (reportId) => {
    const route = reportActions[reportId];
    if (route) {
      navigate(route, { state: { fromDate, endDate } });
    } else {
      setError('No route defined for this report.');
    }
  };

  return (
    <Box
      sx={{
        padding: theme.spacing(5),
        maxWidth: '2000px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        boxShadow: 'None',
        borderRadius: '16px',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(4),
        minHeight: 'calc(100vh - 500px)',
        flexGrow: 1
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{ width: '100%' }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: '#3B82F6',
            textAlign: 'left',
            mb: theme.spacing(2),
            fontSize: '1.875rem',
            fontFamily: 'system-ui, sans-serif'
          }}
        >
          Reports
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          sx={{ width: '100%', mb: theme.spacing(2) }}
        >
          {' '}
          {/* Reduced bottom margin */}
          <RangePicker
            className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            format={'DD/MM/YYYY'}
            defaultValue={[dayjs(), dayjs()]}
            onChange={handleDateChange}
          />
          <Button
            variant="contained"
            color="success"
            sx={{ ml: theme.spacing(2) }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
      {error && (
        <Alert severity="error" sx={{ mb: theme.spacing(2) }}>
          {error}
        </Alert>
      )}
      <Grid container spacing={2}>
        {reportData.map((report) => (
          <Grid item xs={12} sm={6} md={4} key={report.id}>
            <Card
              sx={{
                backgroundColor: '#ffffff',
                color: '#000000',
                width: '100%',
                maxWidth: '400px',
                height: '200px',
                m: theme.spacing(1),
                borderRadius: '16px',
                boxShadow: `0px 6px 15px ${grey[500]}`
              }}
            >
              <CardContent
                sx={{
                  p: theme.spacing(5),
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ height: '100%', justifyContent: 'space-between' }}
                >
                  <Typography
                    component="p"
                    sx={{
                      textAlign: 'left',
                      color: '#000000'
                    }}
                  >
                    {report.title}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    sx={{ mt: 'auto' }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        color: green[500],
                        fontWeight: 700,
                        fontSize: '2rem',
                        mr: theme.spacing(2),
                        textAlign: 'left',
                        fontFamily: 'Roboto, sans-serif'
                      }}
                    >
                      {report.value}
                    </Typography>
                    {report.id !== 'reconcile' && (
                      <Button
                        variant="outlined"
                        size="medium"
                        sx={{
                          color: green[500],
                          borderColor: green[500],
                          borderWidth: 2,
                          fontWeight: 'bold',
                          ml: 'auto',
                          '&:hover': {
                            borderColor: '#16A34A',
                            backgroundColor: 'transparent'
                          }
                        }}
                        onClick={() => handleViewAll(report.id)}
                      >
                        View All
                      </Button>
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ReportsDashboard;
