import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Home';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TimelineIcon from '@mui/icons-material/Timeline';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { GroupAdd } from '@mui/icons-material';
import { Dashboard } from '@mui/icons-material';
import { ThemeContext } from '../index';
import { useMatch } from 'react-router-dom';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText
} from '@mui/material';

function CustomToolbar({ to, label, Icon }) {
  const match = useMatch(to);
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <Link to={to}>
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          className={`h-150 w-150 ${match ? 'bg-green' : ''}`}
          style={{
            display: 'inline-block',
            padding: match ? '10px' : '0px',
            borderRadius: '100%'
          }}
        >
          <div>
            {!match && (
              <Icon
                style={{
                  height: '24px',
                  objectFit: 'contain',
                  fill: theme.palette.mode === 'dark' ? 'white' : '#191818'
                }}
              />
            )}
            {match && (
              <Icon
                style={{
                  height: '24px',
                  objectFit: 'contain',
                  fill: theme.palette.mode === 'dark' && 'white'
                }}
              />
            )}
          </div>
        </div>

        {theme.palette.mode === 'dark' && (
          <div
            className={match ? 'text-green' : ''}
            style={{ fontSize: '12px' }}
          >
            {label}
          </div>
        )}
        {theme.palette.mode === 'light' && (
          <div
            className={match ? 'text-green-dark' : ''}
            style={{ fontSize: '12px' }}
          >
            {label}
          </div>
        )}
      </Toolbar>
    </Link>
  );
}

const SidebarV2 = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const menuItems = [
    {
      text: 'Home',
      route: '/',
      icon: <MapIcon />
    },
    {
      text: 'Call Logs',
      route: '/call-logs',
      icon: <ContactPhoneIcon />
    },
    {
      text: 'Anomaly',
      route: '/anomaly',
      icon: <ReportGmailerrorredIcon />
    },
    {
      text: 'Entry/Exit',
      route: '/logs',
      icon: <AssessmentIcon />
    },
    {
      text: 'People Journey',
      route: '/journey',
      icon: <TimelineIcon />
    },
    {
      text: 'People Management',
      route: '/people',
      icon: <PeopleAltIcon />
    },
    {
      text: 'Village Management',
      route: '/villages',
      icon: <HolidayVillageIcon />
    },
    {
      text: 'People Relationship',
      route: '/relationship',
      icon: <AccountTreeIcon />
    },
    {
      text: 'People Travel Graph',
      route: '/travel',
      icon: <ConnectingAirportsIcon />
    },
    // {
    //   text: 'People Callbook',
    //   route: '/callbook',
    //   icon: <ContactPhoneIcon />
    // },
    {
      text: 'Reconciliation',
      route: '/reconcile',
      icon: <GroupAdd />
    },
    {
      text: 'Reports',
      route: '/reports',
      icon: <Dashboard />
    }
  ];

  function MenuItem({ item, navigate, isLastItem }) {
    const match = useMatch(item.route);

    return (
      <div
        onClick={() => navigate(item.route)}
        className="sidebar_container "
        style={{
          alignItems: 'center',
          textAlign: 'center',
          margin: '8px 0px'
          //?
        }}
      >
        <div className={`flex text-center items-center justify-center `}>
          <div
            className={`flex text-center items-center justify-center ${match ? 'bg-green-500 rounded-full p-2' : 'bg-transparent rounded-none'}`}
          >
            <div
              style={{
                fontSize: '24px', // Adjust this value to fit the icons' size requirements
                display: 'flex', // Ensures the icon itself is centered if it's a component
                alignItems: 'center'
              }}
            >
              {item.icon}
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: '12px',
            textAlign: 'center',
            marginBottom: '8px'
          }}
        >
          {item.text}
        </div>
        {!isLastItem && (
          <hr
            style={{
              borderColor: theme.palette.mode === 'dark' ? '#4E4E4D' : '#e6f0ff'
            }}
          />
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div
        style={{
          flexDirection: 'column',
          // marginTop: "200px",
          alignContent: 'center',
          padding: '12px 24px',
          // paddingRight: "24px",
          border: '1px solid #4E4E4D',
          borderRadius: '100px',
          paddingTop: '12px 0px',
          // paddingBottom: "12px",
          marginLeft: '22px',
          background: theme.palette.mode === 'dark' ? '#191818' : 'white',
          position: 'fixed',
          height: 'fit-content'
        }}
        id="sidebar"
      >
        {menuItems.map((item, i) => (
          <MenuItem
            key={i}
            item={item}
            navigate={navigate}
            isLastItem={i === menuItems.length - 1}
          />
        ))}

        <Link
          target="blank"
          to={'http://localhost:7474/browser'}
          className={`sidebar_container`}
        >
          {/* <div
            style={{
              alignItems: 'center',
              textAlign: 'center',
              margin: '8px 0px',
              backgroundColor:"red",
            }}
          >
            <div
              className="flex justify-center items-center"
              style={{
                textAlign: 'center',
                // padding: "0px 26px", // Padding adjustment when matched
                // height: "40px", // Fixed height for consistency
                // width: "40px", // Fixed width for consistency
                display: 'flex', // Using flex to center the icon
                justifyContent: 'center', // Center horizontally
                alignItems: 'center' // Center vertically
              }}
            >
              <div
                style={{
                  fontSize: '24px', // Adjust this value to fit the icons' size requirements
                  display: 'flex', // Ensures the icon itself is centered if it's a component
                  alignItems: 'center'
                }}
              >
                <CandlestickChartIcon />
              </div>
            </div>
            <div style={{ fontSize: '12px', textAlign: 'center' }}>
              People's Map
            </div>
          </div> */}
          <div></div>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default SidebarV2;
