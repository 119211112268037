import { Modal, notification } from 'antd';
import { create, html } from 'd3';
import { useEffect, useState } from 'react';
import { Constants } from './common/constants';
import useFetchWithAuth from '../utils/fetchWithAuth';
const { REACT_APP_CORE_URL } = Constants;

const NewNodeModal = ({ triggerText, villageId, getNodes, nodeDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [desc, setDesc] = useState('');

  const [error, setError] = useState(false);
  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    if (nodeDetails) {
      setName(nodeDetails?.name);
      setLat(nodeDetails?.lat);
      setLong(nodeDetails?.long);
      setDesc(nodeDetails?.desc);
    }
    setError(false);
  }, []);

  const createNode = (url, id) => {
    // e.preventDefault();

    if (
      name.length == 0 ||
      desc.length == 0 ||
      lat.length == 0 ||
      long.length == 0
    ) {
      setError(true);
      return;
    }

    const query = {};

    query.n_name = name;
    query.n_lat = parseFloat(lat);
    query.n_long = parseFloat(long);
    query.node_description = desc;
    query.village_id = villageId;
    query.node_id = id;

    fetchWithAuth(url ? url : `${REACT_APP_CORE_URL}/createNode`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(query)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode == 201) {
          if (!url) {
            createNode(
              `${REACT_APP_CORE_URL}/createNode`,
              data?.result?.node_id
            );
            notification.success({ message: 'Node Created!!' });
          }
          getNodes();
          setShowModal(false);
        }
      })
      .then(() => {
        setName('');
        setDesc('');
        setLat('');
        setLong('');
        setError(false);
      })
      .catch((err) => console.log(err));
  };

  const updateNode = (url) => {
    if (
      name.length == 0 ||
      desc.length == 0 ||
      lat.length == 0 ||
      long.length == 0
    ) {
      setError(true);
      return;
    }

    const query = {};

    query.n_name = name;
    query.n_lat = parseFloat(lat);
    query.n_long = parseFloat(long);
    query.node_description = desc;
    query.village_id = villageId;
    query.node_id = nodeDetails.nodeId;

    fetchWithAuth(
      url ? url : `${REACT_APP_CORE_URL}/updateNode/${nodeDetails.nodeId}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(query)
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode == 200) {
          if (!url) {
            updateNode(
              `https://vvts-node-backend-poc.futuretech-3i.com/updateNode/${nodeDetails.nodeId}`
            );
            notification.success({ message: 'Node Updated!!' });
          }
          getNodes();
          setShowModal(false);
        }
      })
      .then(() => {
        setName('');
        setDesc('');
        setLat('');
        setLong('');
        setError(false);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nodeDetails) {
      updateNode();
    } else {
      createNode();
    }
  };

  return (
    <div className="mb-3">
      <Modal
        open={showModal}
        title={<div className="text-xl text-gray-600 px-3">Title</div>}
        closeIcon={null}
        footer={null}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap mb-6">
            <div className="w-full md:w-full  px-3 mb-6 md:mb-3 ">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Node Name
              </label>
              <input
                className={
                  'appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white'
                }
                id="grid-first-name"
                type="text"
                placeholder="North Entrance"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {error && name.length == 0 ? (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              ) : null}
            </div>

            <div className="w-full md:w-full  px-3 mb-6 md:mb-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Lattitude
              </label>
              <input
                className={
                  'appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white'
                }
                id="grid-first-name"
                type="number"
                placeholder="20.230"
                value={lat}
                onChange={(e) => setLat(e.target.value)}
              />
              {error && lat.length == 0 ? (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              ) : null}
            </div>
            <div className="w-full md:w-full  px-3 mb-6 md:mb-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Longitude
              </label>
              <input
                className={
                  'appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white'
                }
                id="grid-first-name"
                type="number"
                placeholder="34.340"
                value={long}
                onChange={(e) => setLong(e.target.value)}
              />
              {error && lat.length == 0 ? (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              ) : null}
            </div>
            <div className="w-full md:w-full  px-3 mb-6 md:mb-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Description
              </label>
              <textarea
                className={
                  'appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white'
                }
                id="grid-first-name"
                type="text"
                placeholder="Description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
              {error && desc.length == 0 ? (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end px-3">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="border border-gray-500 rounded px-2 py-1"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 text-white ml-2 rounded px-3 py-1"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <div className="">
        <button onClick={() => setShowModal(true)}>{triggerText}</button>
        {/* <button
          type="button"
          className="bg-green-500 text-white px-2 py-1 rounded"
          onClick={() => setShowModal(true)}
        >
          {triggerText}
        </button> */}
      </div>
    </div>
  );
};

export default NewNodeModal;
