const REACT_APP_CORE_URL = 'http://localhost:2000';
const FLASK_API_URL = 'http://localhost:5700';
const NEO4J_API_URL = 'bolt://localhost:7687';
const NEO4J_PASS = 'password';
export const Constants = {
  REACT_APP_CORE_URL,
  FLASK_API_URL,
  NEO4J_API_URL,
  NEO4J_PASS
};

export const reportActions = {
  new_users: '/people',
  entry_exit_transactions: '/logs',
  anomaly: '/anomaly'
};
