import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Reports from './Reports';
import PrivateRoute from './PrivateRoute';
import GoogleMap from './GoogleMap';
import GoogleMapOffline from './GoogleMapOffline';
import CallLogs from './CallLogs';
import Anomaly from './Anomaly';
import PeopleManagement from './PeopleManagement';
import PeopleJourney from './PeopleJourney';
import Villages from './VillageManagement';
import RelationGraph from './RelationShipGraph';
import Reconciliation from './Reconciliation';
import ReportGenerator from './ReportGenerator';
// import Search from "./Search";
import NewVillage from './NewVillage';
import PeopleTravel from './PeopleTravel';
import Callbook from './Callbook';
import LoginPage from './Login';
import EditResidentDetails from './editResident';
import { AnomalyDetails } from './AnomalyDetails';

const Router = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            {/* <Route element={<GoogleMap />} path="/" exact /> */}
            <Route element={<GoogleMapOffline />} path="/" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            {/* <Route element={<GoogleMap />} path="/" exact /> */}
            <Route element={<CallLogs />} path="/call-logs" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            {/* <Route element={<GoogleMap />} path="/" exact /> */}
            <Route element={<Anomaly />} path="/anomaly" exact />
            <Route element={<AnomalyDetails />} path="/anomaly/:id" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<PeopleTravel />} path="/travel" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<Callbook />} path="/callbook" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<Reports />} path="/logs" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<PeopleManagement />} path="/people" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<PeopleJourney />} path="/journey" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<Villages />} path="/villages" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<RelationGraph />} path="/relationship" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<Reconciliation />} path="/reconcile" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<ReportGenerator />} path="/reports" exact />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              element={<EditResidentDetails />}
              path="/edit-resident-details/:userId"
              exact
            />
          </Route>
          {/* <Route element={<PrivateRoute />}>
            <Route element={<Search />} path="/search" exact />
          </Route> */}

          <Route element={<PrivateRoute />}>
            <Route element={<NewVillage />} path="/new-village" exact />
            <Route
              element={<NewVillage />}
              path="/edit-village/:villageId"
              exact
            />
          </Route>
          <Route element={<LoginPage />} path="/login" exact />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Router;
