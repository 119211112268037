import React, { Fragment, useEffect, useState, useContext } from 'react';
import { ThemeContext } from '../index';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Tooltip
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import LocationIcon from '../Assets/loactionIcon_red.webp';
import moment from 'moment';
import { Button, DatePicker, Select, Spin } from 'antd';
import { useAxiosInstance } from './Interceptor';
import dayjs from 'dayjs';
import PopupModal from './PopupModal';
import '../Styles/PeopleTravel.css';

const PeopleTravel = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [positionArray, setPositionArray] = useState([]);
  const [showAIPredictionButton, setShowAIPredictionButton] = useState(false);
  const [showPredictionModal, setShowPredictionModal] = useState(false);
  const [state, setState] = useState({
    q: { village_id: [], user_id: [] },
    from_date: moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(-30, 'days')
      .add(-18.5, 'hours')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    to_date: moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(1, 'day')
      .add(5.5, 'hours')
      .add(-1, 'minutes')
      .add(59, 'seconds')
      .add(999, 'milliseconds')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  });
  const [villageList, setVillageList] = useState([]);
  const [village, setVillage] = useState();
  const [peopleList, setPeopleList] = useState([]);
  const [people, setPeople] = useState();
  const [output, setOutput] = useState();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    fetchVillage();
  }, []);

  useEffect(() => {
    if (state?.q?.village_id) {
      fetchUser(state?.q?.village_id);
    }
  }, [state?.q?.village_id]);

  const fetchUser = async (id) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/getUserNames`, {
        village_id: id
      });
      let tempValue = [];
      res?.data?.result?.forEach((data) => {
        let obj = { label: data?.user_name, value: data?.user_id };
        tempValue.push(obj);
      });
      setPeopleList(tempValue);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (villageList?.length > 0) {
      let tempValue = ['V1000', 'V4433', 'V7735'];
      const allVillageIDs = villageList.filter((village) =>
        tempValue.includes(village.value)
      );
      setVillage(allVillageIDs);
      let data = { ...state, q: { village_id: tempValue } };
      setState({
        ...state,
        q: { ...state.q, village_id: tempValue, user_id: people }
      });
      fetchData(data);
    }
  }, [villageList]); // eslint-disable-line

  console.log('state', state);

  const fetchVillage = async () => {
    try {
      const res = await axiosInstance.get(`/getVillageDetails`);
      let tempValue = [];
      res?.data?.result?.forEach((data) => {
        let obj = { label: data?.v_name, value: data?.village_id };
        tempValue.push(obj);
      });

      setVillageList(tempValue);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async (formData) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/getMapTransaction`, formData);
      setOutput(res?.data);
      console.log('formData', formData);
      //   setPolylines(res?.data);
      console.log(res?.data?.result, 'dddd');
      if (formData.q?.user_id) {
        console.log('formData', formData);
        setPositionArray(
          res?.data?.result
            ?.map((transaction) => {
              console.log(transaction, 'transaction');
              return transaction?.positions?.length > 0
                ? transaction.positions
                : null;
            })
            .filter(Boolean) || []
        );
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const customIcon = new L.Icon({
    iconUrl: LocationIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const dateHandler = (date) => {
    if (date) {
      const fromDate = moment
        .tz(date[0].toDate(), 'Asia/Kolkata')
        .add(5.5, 'hours')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const toDate = moment
        .tz(date[1].toDate(), 'Asia/Kolkata')
        .add(1, 'day')
        .add(5.5, 'hours')
        .add(-1, 'minutes')
        .add(59, 'seconds')
        .add(999, 'milliseconds')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

      setState({
        ...state,
        from_date: fromDate,
        to_date: toDate
      });
    }
  };

  const submitHandler = async () => {
    try {
      fetchData(state);
      console.log(state);
      // setShowPredictionModal(true); // Show prediction modal after fetching data
      setShowAIPredictionButton(true); // Show the AI prediction button
    } catch (e) {
      console.log(e);
    }
  };

  const predictAIHandler = () => {
    // Logic to handle AI prediction
    setShowPredictionModal(true); // Show the AI prediction modal
  };

  const peopleOnChange = (selectedValues) => {
    setPeople(selectedValues);
    setState({
      ...state,
      q: { ...state.q, user_id: selectedValues }
    });
    setShowPredictionModal(false); // Hide prediction modal when user changes selection
  };

  const onChange = (selectedValues) => {
    setVillage(selectedValues);
    setState({
      ...state,
      q: { ...state.q, village_id: selectedValues }
    });
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  // if am sending a null in user

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            margin: '15px 0px   5px 0px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              Date Range
            </label>
            <RangePicker
              className="dark:rounded-full rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              style={{ height: '2rem' }}
              defaultValue={[
                dayjs(moment(new Date()).add(-1, 'month').format('YYYY-MM-DD')),
                dayjs(moment(new Date()).format('YYYY-MM-DD'))
              ]}
              format={'DD/MM/YYYY'}
              onChange={(value) => dateHandler(value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              Village visited
            </label>
            <Select
              className="map .ant-select-selector w-[20rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
              maxTagCount={'responsive'}
              mode="multiple"
              allowClear
              showSearch
              value={village}
              placeholder="Select a village"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={villageList}
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff',
                border:
                  theme.palette.mode === 'dark'
                    ? '1px solid #4b5563'
                    : '1px solid #d9d9d9',
                height: '32px'
              }}
            />
          </div>
          <div style={{ marginBottom: '6px' }}>
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              People
            </label>{' '}
            <Select
              style={{
                width: '100%',
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff',
                border:
                  theme.palette.mode === 'dark'
                    ? '1px solid #4b5563'
                    : '1px solid #d9d9d9',
                height: '32px'
              }}
              className="map .ant-select-selector mr-3 rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
              showSearch
              value={people}
              allowClear
              placeholder="Select a People"
              onChange={peopleOnChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={peopleList}
            />
          </div>
          <Button
            style={{ marginTop: '20px', backgroundColor: '#2CD074' }}
            type="primary"
            onClick={submitHandler}
          >
            Submit
          </Button>
          <div className="predict-btn-container">
            <Button
              type="primary"
              onClick={predictAIHandler}
              style={{
                display: showAIPredictionButton ? 'block' : 'none',
                backgroundColor: '#2CD074'
              }}
            >
              Predict AI
            </Button>
          </div>
        </div>

        <MapContainer
          center={
            output?.result?.length > 0
              ? output?.result?.[0]?.positions
              : [33.8716, 74.8946]
          }
          zoom={9}
          style={{ height: '90vh', width: '100%' }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {console.log('posi', positionArray)}
          {positionArray.length > 0 ? (
            <Polyline positions={positionArray} color="blue" />
          ) : null}
          {output?.result?.map((transaction, index) => (
            <Fragment key={index}>
              <Marker
                key={index}
                position={transaction.positions}
                icon={customIcon}
              >
                <Tooltip
                  direction="auto"
                  offset={[0, 20]}
                  opacity={1}
                  permanent
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {' '}
                    {transaction?.village_name}{' '}
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      display: 'flex',
                      gap: '10px'
                    }}
                  >
                    <div className="entry">{transaction?.count?.entry}</div>|
                    <div className="exit">{transaction?.count?.exit}</div>
                  </div>
                </Tooltip>
                <Popup>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        Entry{' '}
                      </p>
                      <div
                        className="entry"
                        key={index + 'entry' + crypto.randomUUID}
                      >
                        {transaction?.count?.entry}
                      </div>
                    </div>
                    <div style={{ maxHeight: '7rem', overflow: 'auto' }}>
                      {transaction?.entry?.map((dt, index) => (
                        <div
                          key={index}
                          id={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div>{dt?.name}</div> - <div>{dt?.entryCount}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ maxHeight: '7rem', overflow: 'auto' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        Exit
                      </p>
                      <div className="exit">{transaction?.count?.exit}</div>
                    </div>
                    {transaction?.exit?.map((dt, index) => (
                      <div
                        key={index}
                        id={index}
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div>{dt?.name}</div> - <di>{dt?.exitCount}</di>
                      </div>
                    ))}
                  </div>
                </Popup>
              </Marker>
              <PopupModal
                visible={showPredictionModal}
                onClose={() => setShowPredictionModal(false)}
              />
            </Fragment>
          ))}
        </MapContainer>
      </Spin>
    </Fragment>
  );
};
export default PeopleTravel;
