import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { useEffect, useMemo, useState, useContext } from 'react';
import { ThemeContext } from '../index';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import { Button, DatePicker, Select, Spin, notification } from 'antd';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ViewImageModal from './ViewImageModal';
import { filter } from 'd3';
import { yellow } from '@mui/material/colors';
import { Switch, FormControlLabel } from '@mui/material';
import { Constants } from './common/constants';
import useFetchWithAuth from '../utils/fetchWithAuth';
const { REACT_APP_CORE_URL } = Constants;
const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true
});

const Transmissions = () => {
  // eslint-disable-next-line
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [villages, setVillages] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [people, setPeople] = useState([]);
  const [filters, setFilters] = useState({
    village_id: ['V1000,62 RR'],
    node_id: ['N0006,NODE 6']
  });
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState('5');
  const [totalRecords, setTotalRecords] = useState();
  const [fromDate, setFromDate] = useState(
    moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(-1, 'month')
      .add(1, 'days')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  );
  const [endDate, setEndDate] = useState(
    moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(1, 'day')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  );
  const [checked, setChecked] = useState(false); // State for toggle functionality
  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    if (!checked) {
      getTransactionsV2();
    } else {
      getTransactions();
    }
  }, [checked, currentPage, rowsPerPage]); // eslint-disable-line

  const openNotification = (file) => {
    api['success']({
      message: 'CSV Import',
      description: `${file?.name} imported Successfully`
    });
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'imagebase64',
        header: 'Image',
        size: 150,
        accessorFn: (row) => {
          return (
            row.imagebase64 && (
              <ViewImageModal
                imageClassName={'w-[3rem] h-[3rem] object-contain'}
                image={row.imagebase64}
                metaData={{
                  ...row,
                  name: row?.userInfo?.f_name + ' ' + row?.userInfo?.l_name,
                  entry_or_exit_at: row.entry_or_exit_at
                }}
              />
            )
          );
        }
      },
      {
        accessorKey: 'userInfo.f_name', //access nested data with dot notation
        header: 'First Name',
        size: 150
      },
      {
        accessorKey: 'userInfo.l_name',
        header: 'Last Name',
        size: 150
      },
      {
        accessorKey: 'userInfo.gender',
        header: 'Gender',
        size: 150
      },
      {
        accessorKey: 'userInfo.village.v_name', //normal accessorKey
        header: 'Home Village',
        size: 200
      },
      {
        accessorKey: 'village.v_name', //normal accessorKey
        header: 'Village',
        size: 200
      },
      {
        accessorKey: 'nodeDetails.n_name',
        header: 'Entry / Exit Node',
        size: 150
      },

      {
        accessorKey: 'entry_or_exit_at',
        header: 'Last Recorded Entry / Exit',
        size: 150,
        accessorFn: (row) =>
          moment
            .tz(row.entry_or_exit_at, 'Asia/Kolkata')
            .format('DD-MM-YYYY hh:mm:ss A')
      },
      {
        accessorKey: 'entry_or_exit',
        header: 'Transmission Type',
        size: 150
      },

      {
        accessorKey: 'category',
        header: 'Category',
        size: 150
      },
      {
        accessorKey: 'category_remarks',
        header: 'Category Remarks',
        size: 150
      },
      {
        accessorKey: 'purpose_of_visit',
        header: 'Visit Purpose',
        size: 150
      },
      {
        accessorKey: 'vehicle_type',
        header: 'Vehicle Type',
        size: 150
      },
      {
        accessorKey: 'vehicle_make_model',
        header: 'Vehicle Model',
        size: 150
      },
      {
        accessorKey: 'remarks1',
        header: 'Remarks 1',
        size: 150
      },
      {
        accessorKey: 'remarks2',
        header: 'Remarks 2',
        size: 150
      },
      {
        accessorKey: 'remarks3',
        header: 'Remarks 3',
        size: 150
      },
      {
        accessorKey: 'remarks4',
        header: 'Remarks 4',
        size: 150
      }
    ],
    [] // eslint-disable-line
  );

  const table = useMaterialReactTable({
    columns,
    initialState: {
      density: 'compact',
      columnVisibility: {
        'userInfo.village.v_name': false,
        category_remarks: false,
        purpose_of_visit: false,
        vehicle_type: false,
        vehicle_make_model: false,
        remarks1: false,
        remarks2: false,
        remarks3: false,
        remarks4: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    data: data,
    enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFullScreenToggle: true,
    renderTopToolbarCustomActions: () =>
      checked && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <span
              style={{
                fontSize: '12px',
                marginLeft: '5px',
                marginBottom: '5px'
              }}
            >
              Select Duration
            </span>
            <RangePicker
              className="dark:rounded-full rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              format={'DD/MM/YYYY'}
              defaultValue={[
                dayjs(moment(new Date()).add(-1, 'month').format('YYYY-MM-DD')),
                dayjs(moment(new Date()).format('YYYY-MM-DD'))
              ]}
              onChange={handleDateChange}
            />
          </div>
          <div>
            <span style={{ fontSize: '12px', marginLeft: '15px' }}>
              Select Village
            </span>

            <Select
              className="ml-3 w-[15rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Village"
              showSearch
              allowClear
              mode="multiple"
              maxTagCount={'responsive'}
              options={[{ label: 'Select All', value: 'all' }, ...villages]}
              value={filters.village_id}
              onChange={(e) => {
                if (e.includes('all')) {
                  if (filters.village_id.length === villages?.length) {
                    setFilters({
                      ...filters,
                      village_id: []
                    });
                  } else {
                    setFilters({
                      ...filters,
                      village_id: villages.map((elem) => elem.value)
                    });
                  }
                } else {
                  setFilters({ ...filters, village_id: e });
                }
              }}
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff',
                border:
                  theme.palette.mode === 'dark'
                    ? '1px solid #4b5563'
                    : '1px solid #d9d9d9',
                height: '32px'
              }}
            />
          </div>
          <div>
            <span style={{ fontSize: '12px', marginLeft: '15px' }}>
              Select Node
            </span>
            {console.log(nodes, 'nodes')}
            <Select
              className="ml-3  w-[15rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              maxTagCount={'responsive'}
              placeholder="Node"
              showSearch
              options={[
                {
                  value: '-all-',
                  label: 'Select All Nodes'
                },
                ...nodes
              ]}
              value={filters?.node_id}
              mode="multiple"
              allowClear
              onChange={(e) => {
                if (e.includes('-all-')) {
                  if (filters.node_id.length === nodes?.length) {
                    setFilters({
                      ...filters,
                      node_id: []
                    });
                  } else {
                    setFilters({
                      ...filters,
                      node_id: nodes.map((elem) => elem.value)
                    });
                  }
                } else {
                  setFilters({ ...filters, node_id: e });
                }
              }}
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff',
                border:
                  theme.palette.mode === 'dark'
                    ? '1px solid #4b5563'
                    : '1px solid #d9d9d9',
                height: '32px'
              }}
            />
          </div>

          <div>
            <span style={{ fontSize: '12px', marginLeft: '15px' }}>
              Select People
            </span>
            <Select
              className="ml-3 rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="People"
              showSearch
              allowClear
              options={people}
              onChange={(e) => {
                if (e) {
                  const id = e?.split(',');
                  setFilters({ ...filters, user_id: id[0] });
                } else {
                  setFilters({ ...filters, user_id: undefined });
                }
              }}
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff',
                border:
                  theme.palette.mode === 'dark'
                    ? '1px solid #4b5563'
                    : '1px solid #d9d9d9',
                height: '32px'
              }}
            />
          </div>
          <Button
            style={{ marginTop: '20px', backgroundColor: '#2CD074' }}
            onClick={getTransactions}
            type="primary"
          >
            Submit
          </Button>
        </div>
      )
  });

  const { RangePicker } = DatePicker;

  useEffect(() => {
    getVillagesList();
  }, []); // eslint-disable-line

  useEffect(() => {
    getPeopleList();
  }, filters?.village_id);

  useEffect(() => {
    // if (filters.village_id) {
    getNodes();
    // }
  }, [filters.village_id]); // eslint-disable-line

  const getVillagesList = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getVillageDetails`, {
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((village, i) => {
          return {
            label: village.v_name,
            value: village.village_id + ',' + village.v_name
          };
        });
        setVillages(options);
        setFilters({
          ...filters
        });
      })
      // .then(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getNodes = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getNodeByVillageId`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        village_id: filters.village_id.map((elem) => elem?.split(',')[0])
      })
    })
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((node, i) => {
          return {
            label: node.n_name,
            value: node.node_id + ',' + node.n_name
          };
        });
        setNodes(options);
        setFilters({
          ...filters,
          node_id: options
            .filter((elem) => filters?.node_id?.includes(elem.value))
            .map((node) => node.value)
        });

        console.log(
          options
            .filter((elem) => {
              console.log(filters?.node_id, elem.value, 'check');
              filters?.node_id?.includes(elem.value);
            })
            .map((value) => value),
          'sovnfdjkvn'
        );

        //select all nodes by default
        // setFilters({
        //   ...filters,
        //   node_id: options.map((node) => node.value?.split(",")[0]),
        // });
      })
      // .then(() => getTransactions())
      .then(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getTransactionsV2 = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getTransactionV2`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        limit: parseInt(rowsPerPage),
        page: currentPage
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.result ? data?.result : []);
        setTotalRecords(data.totalRecords);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getTransactions = () => {
    setLoading(true);

    const fils = {
      ...filters,
      village_id: filters?.village_id?.map((elem) => elem.split(',')[0]),
      node_id:
        filters.node_id.length > 0
          ? { in: filters?.node_id?.map((elem) => elem.split(',')[0]) }
          : undefined
    };

    fetchWithAuth(`${REACT_APP_CORE_URL}/getTransaction`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: fils,
        from_date: fromDate,
        to_date: endDate,
        limit: parseInt(rowsPerPage),
        page: currentPage
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.result ? data?.result : []);
        setTotalRecords(data.totalRecords);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getPeopleList = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getUserNames`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        village_id: filters?.village_id.map((elem) => elem.split(',')[0])
      })
    })
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((person, i) => {
          return {
            label: person.user_name,
            value: person.user_id + ',' + person.user_name
          };
        });
        setPeople(options);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDateChange = (e, string) => {
    if (e) {
      setFromDate(
        moment
          .tz(e[0].toDate(), 'Asia/Kolkata')
          .add(5.5, 'hours')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
      setEndDate(
        moment
          .tz(e[1].toDate(), 'Asia/Kolkata')
          .add(1, 'day')
          .add(5.5, 'hours')
          .add(-1, 'minutes')
          .add(59, 'seconds')
          .add(999, 'milliseconds')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
    } else {
      setFromDate(
        moment
          .tz(dayjs(), 'Asia/Kolkata')
          .add(5.5, 'hours')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
      setEndDate(
        moment
          .tz(dayjs(), 'Asia/Kolkata')
          .add(1, 'day')
          .add(5.5, 'hours')
          .add(-1, 'minutes')
          .add(59, 'seconds')
          .add(999, 'milliseconds')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
    }
  };

  const handleExportData = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/v1/exportall`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'blob'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = moment().format('YYYYMMDD-') + '-CommandCenter.enc';

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .then(() => setLoading(false))
      .catch((err) => {
        console.error('Download error:', err);
        setLoading(false);
      });
  };

  const handleFileImport = (e) => {
    setLoading(true);
    const files = e.target.files;
    if (files) {
      setFile(e.target.files[0]);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      fetchWithAuth(`${REACT_APP_CORE_URL}/v4/importTransactionData`, {
        method: 'POST',
        credentials: 'include',
        body: formData
      })
        .then(() => {
          if (!checked) {
            getTransactionsV2();
          } else {
            getTransactions();
          }
          openNotification(file);
          setFile();
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  const handleNextPage = () => {
    if (currentPage === Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage === 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      {contextHolder}
      <div className="h-auto mt-5 table-container mx-[auto] overflow-auto ">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            gap: '20px'
          }}
        >
          <div className="flex">
            <button
              style={{
                backgroundColor: '#2CD074',
                border: '1px solid #2CD074',
                marginRight: '5px'
              }}
              className="custom-button"
              onClick={handleExportData}
            >
              Export
            </button>
            <label
              htmlFor="fileInput"
              style={{
                cursor: 'pointer',
                backgroundColor: '#2CD074',
                border: '1px solid #2CD074'
              }}
              className="custom-button"
            >
              Import Data
            </label>
            <input
              id="fileInput"
              style={{ display: 'none' }}
              type="file"
              // accept=".csv,.xlsx,.xls,.zip"
              onChange={handleFileImport}
            />
            {file && (
              <div
                style={{ margin: '5px', display: 'flex', alignItems: 'center' }}
              >
                {file?.name}{' '}
                <ClearIcon
                  onClick={() => setFile()}
                  style={{
                    border: '1px solid black',
                    margin: '0px 10px 0px 10px',
                    borderRadius: '3px'
                  }}
                />
              </div>
            )}
          </div>

          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleToggle}
                  style={{ color: '#2CD074' }}
                />
              }
              label="Show Filter"
              style={{ marginLeft: '4px' }}
            />
          </div>
        </div>

        <MaterialReactTable table={table} />

        {totalRecords && (
          <div className="mt-3 mb-5 flex justify-between">
            <div className="flex items-center rows-per-page">
              <span>Rows Per Page:</span>
              <Select
                className="mx-2"
                options={[
                  {
                    value: '5',
                    label: '5'
                  },
                  {
                    value: '10',
                    label: '10'
                  },
                  {
                    value: '20',
                    label: '20'
                  }
                ]}
                onChange={(e) => {
                  setRowsPerPage(e);
                  setCurrentPage(1);
                }}
                value={rowsPerPage}
              />
            </div>
            <div className="flex items-center ">
              <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
              <div>
                {currentPage}/{Math.ceil(totalRecords / parseInt(rowsPerPage))}
              </div>
              <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default Transmissions;
