import React, { useState, useMemo, useEffect, useContext } from 'react';
import { ThemeContext } from '../index';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { Button } from '@mui/material';
import { Select, Spin } from 'antd';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import ViewImageModal from './ViewImageModal';
import { Constants } from './common/constants';
import useFetchWithAuth from '../utils/fetchWithAuth';
const { REACT_APP_CORE_URL } = Constants;

const PeopleManagement = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [peopleData, setPeopleData] = useState([]);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [villageOptions, setVillageOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState('5');
  const [totalRecords, setTotalRecords] = useState();
  const [loading, setLoading] = useState(false);
  const fetchWithAuth = useFetchWithAuth();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'imagebase64', //normal accessorKey
        header: 'Image',
        size: 50,
        accessorFn: (row) => {
          return (
            <ViewImageModal
              imageClassName="w-[3rem] h-[3rem] object-cover"
              image={row.imagebase64}
              metaData={{
                ...row,
                userInfo: { f_name: row.f_name, l_name: row.l_name }
              }}
            />
          );
        }
      },
      {
        accessorKey: 'f_name',
        header: 'First Name',
        size: 150
      },
      {
        accessorKey: 'l_name',
        header: 'Last Name',
        size: 150
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
        size: 100
      },
      {
        accessorKey: 'village.v_name', //normal accessorKey
        header: 'Village',
        size: 150
      },
      {
        accessorKey: 'age', //normal accessorKey
        header: 'Age',
        size: 50
      },
      {
        accessorKey: 'aadhar', //normal accessorKey
        header: 'Aadhaar',
        size: 150
      },

      {
        accessorKey: 'contact', //normal accessorKey
        header: 'Contact',
        size: 150
      },

      {
        accessorKey: 'city', //normal accessorKey
        header: 'City',
        size: 150
      },
      {
        accessorKey: 'country', //normal accessorKey
        header: 'Country',
        size: 150
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 150
      }
    ],
    []
  );

  useEffect(() => {
    getVillageOptions();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (filters?.village_ids) {
      getPeopleOptions();
    }
  }, [filters?.village_ids]);

  useEffect(() => {
    if (filters?.village_ids?.length > 0) getPeopleData();
  }, [currentPage, rowsPerPage, villageOptions, filters?.user_id]); // eslint-disable-line

  const getPeopleData = () => {
    const village_ids = filters?.village_ids?.map(
      (element) => element.split(',')[0]
    );

    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getUserInfo`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          ...filters,
          village_ids
        },
        limit: parseInt(rowsPerPage),
        page: currentPage
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setPeopleData(data.result);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getVillageOptions = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getVillageDetails`, {
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => {
        const options = data.result
          .filter((elem) => elem.v_name != 'Khan Sahib')
          .map((village, i) => {
            return {
              label: village.v_name,
              value: village.village_id + ',' + village.v_name
            };
          });
        console.log(options, 'optionss');
        setVillageOptions(options);
        setFilters({ village_ids: options.map((vill) => vill.value) });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getPeopleOptions = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getUserNames`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        village_id: filters?.village_ids?.map((elem) => elem.split(',')[0])
      })
    })
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((person, i) => {
          return {
            label: person.user_name,
            value: person.user_id + ',' + person.user_name
          };
        });
        setPeopleOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const table = useMaterialReactTable({
    columns,
    initialState: {
      density: 'compact',
      columnVisibility: {
        aadhar: false,
        // user_id: false,
        city: false,
        country: false,
        wanted: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    data: peopleData,
    enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        window.open(`/edit-resident-details/${row.original.user_id}`, '_blank');
      },
      sx: {
        cursor: 'pointer' //you might want to change the cursor too when adding an onClick
      }
    }),
    renderTopToolbarCustomActions: () => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          marginLeft: '1rem'
        }}
      >
        <div>
          <label style={{ fontSize: '12px', marginLeft: '5px' }}>Village</label>{' '}
          <Select
            placeholder="Select Village"
            options={[
              {
                label: 'Select All',
                value: 'all'
              },
              ...villageOptions
            ]}
            style={{
              background: theme.palette.mode === 'dark' ? '#374151' : '',
              color: theme.palette.mode === 'dark' && '#ffffff',
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid #4b5563'
                  : '1px solid #d9d9d9'
            }}
            mode="multiple"
            allowClear
            value={filters.village_ids ? filters.village_ids : null}
            onChange={(e) => {
              if (e.includes('all')) {
                if (filters?.village_ids.length == villageOptions.length) {
                  const ids = villageOptions.map((vill) => vill.value);
                  setFilters({ ...filters, village_ids: [] });
                } else {
                  const ids = villageOptions.map((vill) => vill.value);
                  setFilters({ ...filters, village_ids: ids });
                }
              } else {
                setFilters({ ...filters, village_ids: e });
              }
            }}
            showSearch
            className="w-[20rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
            maxTagCount={'responsive'}
          />
        </div>
        <div>
          <label style={{ fontSize: '12px', marginLeft: '5px' }}>People</label>{' '}
          <Select
            placeholder="Select People"
            options={peopleOptions}
            onChange={(e) => {
              console.log(e, 'eweee');
              if (e) {
                const id = e.split(',')[0];
                setFilters({ ...filters, user_id: id });
              } else {
                setFilters({ ...filters, user_id: undefined });
              }
            }}
            allowClear
            showSearch
            className="rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
            style={{
              background: theme.palette.mode === 'dark' ? '#374151' : '',
              color: theme.palette.mode === 'dark' && '#ffffff',
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid #4b5563'
                  : '1px solid #d9d9d9',
              height: '32px'
            }}
          />
        </div>
        <Button
          type="primary"
          style={{ marginTop: '20px', backgroundColor: '#2CD074' }}
          onClick={getPeopleData}
        >
          Submit
        </Button>
      </div>
    )
  });

  const handleNextPage = () => {
    if (currentPage === Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage === 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <div id="peopleManagementTable">
        <MaterialReactTable table={table} />
        <div className="mt-3 mb-5 flex justify-between">
          <div className="flex items-center rows-per-page">
            <span>Rows Per Page:</span>
            <Select
              className="mx-2 w-[3.8rem] h-[3rem]"
              options={[
                {
                  value: '5',
                  label: '5'
                },
                {
                  value: '10',
                  label: '10'
                },
                {
                  value: '20',
                  label: '20'
                }
              ]}
              onChange={(e) => {
                setRowsPerPage(e);
                setCurrentPage(1);
              }}
              value={rowsPerPage}
            />
          </div>
          <div className="flex items-center ">
            <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
            <div>
              {currentPage}/{Math.ceil(totalRecords / parseInt(rowsPerPage))}
            </div>
            <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PeopleManagement;
