import React, { useContext, useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import AuthContext from '../context/authContext';
import { ThemeContext } from '../index';
import SidebarV2 from './SidebarV2';
const PrivateRoute = () => {
  const { authenticated, userType } = useContext(AuthContext);
  // const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);
  useEffect(() => {
    if (authenticated) {
      // navigate("/");
    } else {
      navigate('/login');
    }
  }, [authenticated]);

  return (
    <React.Fragment>
      {console.log(authenticated, 'authenticated')}
      <div className="app-container">
        <div
          className="header"
          style={{
            borderBottom: theme.palette.mode === 'dark' && '1px solid #4E4E4D',
            backgroundColor: theme.palette.mode === 'dark' && '#191818'
          }}
          id="header"
        >
          <Header />
        </div>
        {/* <Sidebar />
        {authenticated ? <Outlet /> : <Navigate to="/login" />} */}
        <div
          className="grid grid-cols-12 items-center"
          style={{ height: '100vh', padding: '22px' }}
        >
          <div className="col-span-1 flex justify-center items-center">
            {' '}
            {/* Sidebar container */}
            <SidebarV2 />
          </div>
          <div className="content-container col-span-11">
            {authenticated ? <Outlet /> : <Navigate to="/login" />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivateRoute;
